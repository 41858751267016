/* Modal Styles */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}

.modal.show {
  display: block; /* Show the modal */
}

.modal-content {
  background-color: #1F1F1F; /* Adjusted to match your table container */
  margin: 5% auto; /* 5% from the top and centered */
  padding: 20px;
  border-radius: 8px; /* Matched to your table container */
  width: 80%; /* Could be more or less, depending on screen size */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Optional: Adds shadow to the container */
  overflow-x: auto; /* For responsive tables */
}

/* If you have a close button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Audit Log Table Styles */
.audit-logs-table {
  width: 100%; /* Full width of the modal content */
  border-collapse: collapse;
  background-color: #282c34; /* Darker background for the table */
}

.audit-logs-table th,
.audit-logs-table td {
  border-bottom: 1px solid #3a3b3c; /* Slightly lighter border for separation */
  text-align: left;
  padding: 16px; /* Adjust padding to match the image */
  font-size: 0.9em; /* Adjust font size as needed */
}

.audit-logs-table th {
  background-color: #202225; /* Slightly different background for header */
  color: #ffffff;
}

.audit-logs-table tr {
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.audit-logs-table tr:hover {
  background-color: #292b2f; /* Hover state color */
}

/*positioning adjustment */
.modal-content {
  background-color: #1F1F1F; /* Adjusted to match your table container */
  /* Centering the modal exactly on top of the table */
  margin: 5% 15%; /* 5% from the top and 15% from the sides to match the table container */
  padding: 20px;
  border-radius: 8px; /* Matched to your table container */
  width: 80%; /* Same as the table container */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Optional: Adds shadow to the container */
  position: relative; /* To enable positioning within the fixed parent */
  overflow-x: auto; /* For responsive tables */
}

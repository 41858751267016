
/* TaskRequestsTable.css */
.task-requests-table-container {
  position: relative;
  width: 80%; /* Full width of the container */
  color: #ffffff;
  left:15%;
  background-color: #1F1F1F; /* Adjust the background color to match your theme */
  padding: 20px;
  border-radius: 8px; /* You might want to adjust this to match the border radius in the image */
  margin: 20px 0; /* Assuming you want to keep the top and bottom margin */
  overflow-x: auto; /* For responsive tables */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Optional: Adds shadow to the container */
}

.task-requests-table {
  width: 100%; /* Full width of the container */
  border-collapse: collapse;
  background-color: #282c34; /* Darker background for the table */
}

.task-requests-table th,
.task-requests-table td {
  border-bottom: 1px solid #3a3b3c; /* Slightly lighter border for separation */
  text-align: left;
  padding: 16px; /* Adjust padding to match the image */
  font-size: 0.9em; /* Adjust font size as needed */
}

.task-requests-table th {
  background-color: #202225; /* Slightly different background for header */
  color: #ffffff;
}

.task-requests-table tr {
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.task-requests-table tr:hover {
  background-color: #292b2f; /* Hover state color */
}

/* Styling for status columns */
.task-requests-table td.status-analyzing {
  color: #FFD700; /* Gold color for analyzing status, can adjust based on your design */
}

.task-requests-table td.status-ready {
  color: #90EE90; /* Light green color for ready status, adjust as needed */
}

/* Optional: Styling for sorting icons */
.sort-icon {
  margin-left: 5px;
  font-size: 0.7em;
  cursor: pointer;
}

/* Optional: Add media query for responsive design */
@media (max-width: 768px) {
  .task-requests-table-container {
    padding: 10px;
    margin: 10px 0; /* Adjust the margin for smaller screens */
  }

  .task-requests-table th,
  .task-requests-table td {
    padding: 12px; /* Smaller padding for smaller screens */
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Add some space above the pagination */
}

.pagination button {
  margin: 0 5px; /* Add some space between buttons */
}
.status-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.green {
  background-color: green;
}

.flashing {
  animation: flashAnimation 1.5s infinite;
}

@keyframes flashAnimation {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}
.queued {
  background-color: #add8e6; /* Or any color you prefer */
}
.flashing {
  background-color: #E47907; /* Assuming you want the flashing dot to be blue */
  animation: flashAnimation 2s infinite;
}
/* Add this CSS to your stylesheet */

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-request-button {
  background-color: #E47907; /* Blue background color, you can change it */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/* Adjust the styles as needed */

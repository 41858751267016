
/* Primary button or accent color */
.sign-out-button,
.app-logo {
  background-color: rgb(30, 30, 255); /* blue for logo and sign out button */

}

/* Hover effect for sign-out button */
.sign-out-button:hover {
  background-color: rgb(255, 31, 30); /* red for hover effect */
}
/* Styles for the hamburger menu icon */
.menu-icon {
    font-size: 2rem;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    z-index: 1000; /* Ensuring it's above other elements */
}



/* Ensure the menu is aligned properly with the header */
.app-header {
    position: fixed; /* Fixed position to stay in view */
    width: 100%; /* Full width */
    top: 0;
    z-index: 15; /* Higher than .side-menu to stay on top */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Keyframes for the logo spin */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Prefers-reduced-motion media query */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  display: flex;
  /*justify-content: space-between;*/
  /*align-items: center;*/
  padding: 10px;
  color: white;
}

.app-branding {
  /*display: flex;*/
  /*align-items: center;*/
}

/* Logo and sign-out button styles */
.app-logo {
  width: 50px;
  margin-right: 10px;
}

.sign-out-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-out-button:hover {
  background-color: #d32f2f;
}
.app-header {
  display: flex;
  justify-content: space-between; /* This will push the branding and icons to the opposite ends */
  align-items: center;
  padding: 10px;
  color: white;
}

/*.header-icons {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

.header-icon {
  color: white; /* Match the color with your header's text color */
  font-size: 1.5rem; /* Adjust size as necessary */
  margin-left: 10px; /* Space between icons */
  margin-right: 10px;
  cursor: pointer;
}

.header-icon:hover {
  color: #aaa; /* Lighter color on hover for better effect */
}

/* Tooltip styles for header icons */
.header-icon[title]:hover:after {
  content: attr(title);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  white-space: nowrap; /* Ensure the tooltip text doesn't wrap */
  background-color: black;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  z-index: 1001;
  opacity: 0; /* Initially hide the tooltip */
  /*transition: opacity 0.3s ease; !* Add a transition for smooth visibility *!*/
}

.header-icon[title]:hover:after {
  /* Change the opacity to make the tooltip visible on hover */
  opacity: 1;
}
.header-right-section {
  /* Add this new style to stack icons to the right */
  display: flex;
  align-items: center;
  margin-left: auto; /* Push the icons to the right */
}
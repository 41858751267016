/*App.css*/
body, p, h1, h2, h3 /* Add other elements here */
{
  font-family: 'Archivo', sans-serif; /* Use 'Archivo' as the font family */
}
/* General styles */
.App {
  text-align: center;
}


/* App container and header styles */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #282c34;


}


/* Main content and card container styles */
.app-content {
  flex-grow: 1;
  padding: 20px;
}

.cards-container {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Ensure cards have the same height */
  gap: 20px;
  margin-top: 50px;
}

/* Card styles */
.card {
  background: rgb(37, 37, 37);
  width: 280px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  color: white;
  border: 1px solid rgba(255,255,255, 0.2);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
}

.card-top {
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0,0,0, 0.1);
  flex: 1;
}

.card-bottom {
  padding: 15px;
  text-align: left;
  flex: 2;
}

.card-icon {
  color: #E47907;
  font-size: 4rem;
}

.card-content h2 {
  margin-top: 0;
  color: #E47907;
}

.card-content p {
  font-size: 0.9rem;
  color: #666;
}

/* Specific styles for active and disabled cards */
.active-card .card-icon {
  color: #E47907;
}

.disabled-card {
  background-color: #f5f5f5;
  cursor: not-allowed;
  color:lightgray
}

.disabled-card .card-icon {
  color: grey;
}

.disabled-card .card-top {
  background-color: #e0e0e0;
}

.disabled-card:hover .card-icon,
.disabled-card:hover .card-content h2,
.disabled-card:hover .card-content p {
  color: #ccc;
}


/* Tooltip styles */
.tooltip {
  position: absolute;
  z-index: 2; /* Increase z-index */
  top: 0px; /* Adjust as needed for spacing from the top */
  right: 00px; /* Adjust as needed for spacing from the right */
  width: 120px;
  height: 30px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0 2px 0px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.disabled-card .tooltip {
  /* Styles for tooltip */
   visibility: visible;
}

/* Responsive main heading */


.main-heading {
  font-size: 2rem; /* Set the font size one size larger than the card heading */
  font-weight: 600; /* Assuming the card heading is bold */
  margin: 2rem 0 1rem; /* Add top and bottom margin */
  text-align: center; /* Ensure the heading is centered above the cards */
  color:white
}


/* Style for the email/username next to the sign out button */
.user-email {
  margin-right: auto; /* Pushes everything else to the right */
  padding: 0 1rem; /* Spacing on left and right */
  color: white; /* Assuming you want the email in white */
  font-size: 1rem; /* Match the font size to the rest of the header */
  line-height: 2.5rem; /* Vertically center the text with the button */
}

/* Adjust the .sign-out-button to align with the email */
.sign-out-button {
  /* ... existing styles ... */
  margin-left: 0.5rem; /* Add margin to separate it from the username/email */
}


/* Make sure the card has a relative position */
.card {
  position: relative;
  /* ... existing styles ... */
}

 .disabled-card .tooltip {
  /* Styles for tooltip */
   visibility: visible;
 }

 .disabled-card .card-bottom{
  text-color:black
 }

 /* Tooltip styles */
.tooltip {
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0px;
  width: 120px;
  height: 30px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  opacity: 1; /* Set opacity to 1 for permanent visibility */
  transition: opacity 0.3s ease-in-out;
  visibility: visible; /* Set visibility to visible */
}

/* Remove hover effect for tooltip */
.card:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/*.app-header {*/
/*  background-color: #282c34;*/
/*  display: flex;*/
/*  justify-content: space-between; !* Adjust this to align items as needed *!*/
/*  align-items: center;*/
/*  padding: 10px;*/
/*  color: white;*/
/*  !* Add more space on the left if the hamburger menu overlaps *!*/
/*  padding-left: 50px; !* Adjust this value as needed *!*/
/*}*/
/* Main background color */
.app-container {
  background-color: rgb(31, 30, 30); /* dark gray background */
}

/* Header background color */
.app-header {
  background-color: rgb(33, 33, 33); /* slightly lighter gray for the header */
  color: white; /* This will keep the text in the header white */
}


/* Additional elements, if you want to use the bright green */
.active-card .card-icon {
  color: #E47907; /* bright green for active icons */
}



/* Make sure the header does not overlap with the side menu */
.app-container {
    padding-top: 60px; /* Adjust this value to the height of your header */
}

/* Add this CSS to your stylesheet */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  z-index: 999; /* Ensure it's above other content */
}

.loading-spinner {
  border: 8px solid #E47907; /* #E47907 border */
  border-top: 8px solid transparent; /* Transparent top border */
  border-radius: 50%; /* Circle shape */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Rotate animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

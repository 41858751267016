/*SideMenu.css*/

/*hamburger menu*/

/* Styles for the side menu to display below the header */
.side-menu {
    position: fixed; /* Fixed position to stay in view */
    top: 200px; /* Adjust this value to the height of your header */
    left: 0;
    width: 250px; /* Fixed width for the side menu */
    height: calc(100% - 60px); /* Adjust the height to account for the header */
    background-color: rgb(33, 33, 33); /* Dark gray background from the image */
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    padding: 0; /* Removed padding here */
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 10; /* Ensuring it's above the content but below the menu icon */
}

/* Styles for showing the side menu */
.app-container.menu-open .side-menu {
    transform: translateX(0);
}

/* Side Menu List Item Improvements */
.side-menu li {
    display: flex;
    align-items: center;
    padding: 10px 20px; /* Adjusted padding for uniformity */
    color: white;
    border-bottom: 1px solid rgb(30, 30, 255); /* Blue separator line between menu items */
}

/* Side Menu Button Improvements for better flat design */
.side-menu button {
    background-color: transparent;
    color: white;
    text-align: left;
    padding: 10px 20px; /* Adjusted padding for better spacing */
    width: 100%;
    border: none;
    border-radius: 0;
    transition: background-color 0.2s ease-in-out;
    font-size: 0.95rem;
    font-weight: 650;
    text-transform: none; /* Removed uppercase for better readability */
}

/* Improved hover effect for menu item buttons */
.side-menu button:hover {
    background-color: rgb(50, 50, 50); /* Slightly darker on hover for better effect */
}

/* Menu items styling */
.side-menu .menu-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Icon styles */
.side-menu .icon {
    font-size: 1.2rem;
    margin-right: 10px;
    margin-left: 10px;
    color: white; /* White color for icons */
}


/* Styles for the hamburger menu icon */
.menu-icon {
    font-size: 2rem;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

/* Styles for the side menu */
.side-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 15%;
    height: 100%;
    background-color: #333;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

/* Styles for showing the side menu */
.app-container.menu-open .side-menu {
    transform: translateX(0);
}

/* Side Menu Improvements */
.side-menu {
    background-color: #2c313a; /* Darker shade for better visibility */
    padding: 20px 0px; /* Top and bottom padding */
    width: 250px; /* Adjust width as needed */
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Use a modern font */
}

.side-menu li {
    padding: 15px 20px; /* Uniform padding for each menu item */
    display: flex; /* Use flexbox for aligning icon and text */
    align-items: center; /* Center items vertically */
}

.side-menu button {
    background-color: transparent; /* Remove the background to make it flat */
    color: #ffffff; /* White color for text */
    text-align: left; /* Align text to the left */
    padding: 10px; /* Padding inside the button */
    width: 100%; /* Full width */
    border: none; /* Remove borders */
    border-radius: 0; /* Remove border radius for a flat design */
    transition: background-color 0.2s ease-in-out; /* Smooth transition for hover effect */
    font-size: 0.95rem; /* Smaller font size */

    /*text-transform: uppercase;*/ /* Uppercase text */
    font-weight: 650; /* Lighter font weight */
}

.side-menu button:hover {
    background-color: #3a3f47; /* Lighter background on hover */
}

/* Icon styles */
.side-menu .icon {
    font-size: 1.2rem; /* Icon size */
    margin-right: 10px; /* Space between icon and text */
}

/* Optional: Add a separator line between menu items */
.side-menu li:not(:last-child) {
    border-bottom: 1px solid #3a3f47;
}

/* Icon styles */
.side-menu .icon {
    font-size: 1.2rem; /* Control the size of the icon */
    margin-right: 10px; /* Space between icon and text */
    color: #ffffff; /* Set the color if needed */
}

/* Overflow hidden when menu is open (for app-container) */
.app-container.menu-open {
    overflow: hidden;
}

/* Menu items styling */
.side-menu .menu-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.side-menu .menu-items li {
    margin-bottom: 10px;
}

.side-menu .menu-items button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #444;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
}

/* Hover effect for menu item buttons */
.side-menu .menu-items button:hover {
    background-color: #555;
}

.app-branding {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align to the left side */
}

.menu-icon {
    position: absolute;
    top: 20px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    z-index: 10; /* Ensure it's above other elements */
}

.side-menu {
    position: fixed; /* Fixed or absolute depending on your layout */
    top: 53px;
    width: 250px;
    height: 100%;
    background-color: #333;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    flex-direction: column;
    z-index: 9; /* Set z-index to ensure it's above other content but below the menu icon */
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

/* Show the menu when it's open - make sure the class is being added to your container properly */
.app-container.menu-open .side-menu {
    transform: translateX(0);
}

/* Reset the overflow property if you want to scroll the menu */
.app-container.menu-open {
    overflow: visible;
}

/* Side Menu Improvements */
.side-menu {
    background-color: #3a3f47; /* Darker shade for better visibility */
    padding: 20px 10px; /* Top and bottom padding */
    width: 250px; /* Adjust width as needed */
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.side-menu li {
    padding: 10px 0; /* Top and bottom padding for each menu item */
    color: #ffffff;
    font-size: larger;
    display: flex;
}

.side-menu button {
    /*background-color: transparent; !* Remove the background to make it flat *!*/
    /*color: #ffffff; !* White color for text *!*/
    /*text-align: left; !* Align text to the left *!*/
    /*padding: 10px; !* Padding inside the button *!*/
    /*width: 100%; !* Full width *!*/
    /*border: none; !* Remove borders *!*/
    /*border-radius: 0; !* Remove border radius for a flat design *!*/
    /*transition: background-color 0.2s ease-in-out; !* Smooth transition for hover effect *!*/
}

.side-menu button:hover {
    background-color: #505760; /* Lighter background on hover */
}

/*hamburger menu*/

/* Styles for the hamburger menu icon */
.menu-icon {
    font-size: 2rem;
    color: white; /* Keeping the color white for contrast */
    cursor: pointer;
    margin-right: 10px; /* You may need to adjust this based on the actual layout */
    z-index: 9999;
}

/* Styles for the side menu */
.side-menu {
    position: fixed; /* Changed from absolute to fixed for consistency on scroll */
    top: 0;
    left: 0;
    width: 200px; /* Adjusted width to a fixed value */
    height: 100%;
    background-color: rgb(31, 30, 30); /* Dark gray almost black from the image */
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    /*padding: 20px;*/
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 10; /* Ensuring it's above other elements */
}

/* Styles for showing the side menu */
.app-container.menu-open .side-menu {
    transform: translateX(0);
}

/* Side Menu Improvements */
.side-menu {
    position: fixed;
    top: 10vh;
    left:0px;
    background-color: rgb(33, 33, 33); /* Using the darker gray for the side menu background */
    padding: 0px 0px;
}

.side-menu li a {
    /*padding: 5px 10px; !* Keeping uniform padding for each menu item *!*/
    display: flex; /* Using flexbox for aligning icon and text */
    align-items: center; /* Centering items vertically */
    color: white; /* Text color for menu items */
}

.side-menu button {
    display:flex;
    background-color: transparent; /* Flat button style */
    color: white; /* White color for text */
    text-align: left; /* Text aligned to the left */
    /*padding: 10px; !* Padding inside the button *!*/
    width: 100%; /* Button takes full width */
    border: none; /* No borders for the button */
    border-radius: 0; /* No border-radius for a flat design */
    transition: background-color 0.2s ease-in-out; /* Smooth transition for hover effect */
    font-size: 0.85rem; /* Smaller font size */

}

.side-menu button:hover {
    /*background-color: #1F1F1F; !* Using the blue color for the hover background *!*/
    background-color:#151515;
}

/* Adding specificity for active menu item if needed */
.side-menu .menu-item-active {
    background-color: black; /* Green background for active items */
}


.menu-icon {
    position: absolute;
    top: 70px; /* Adjusted as needed */
    left: 0px; /* Adjusted as needed */
    z-index: 10; /* Ensuring it's above other elements */
}

/* PrivacySecurity.css */
.privacy-security-container {
  width: 80%; /* Full width of the container */
  color: #ffffff;
  background-color: #1F1F1F; /* Dark theme background */
  padding: 20px;
  border-radius: 8px; /* Border radius for the container */
  margin: 20px auto; /* Center the component with margin */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Shadow for depth */
}

.visibility-settings {
  display: flex;
  justify-content: space-around; /* Space out the toggles evenly */
  align-items: center;
  margin: 20px 0; /* Space above and below the toggle section */
}

.toggle-container {
  display: flex;
  align-items: center;
  position: relative; /* For absolute positioning of the slider */
}

.toggle-container input[type="checkbox"] {
  opacity: 0; /* Hide the default checkbox */
  width: 40px; /* Set width for clickable area */
  height: 20px; /* Set height for clickable area */
  position: absolute;
  cursor: pointer;
}

.toggle-slider {
  background-color: #333; /* Default background for slider */
  border: 1px solid #555; /* Border to make the slider stand out */
  height: 20px;
  width: 60px;
  border-radius: 20px; /* Rounded edges for the slider */
  transition: background-color 0.2s; /* Transition for color change */
  position: relative; /* Position relative to the label */
}

.toggle-slider::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 4px;
  bottom: 0;
  background-color: white;
  transition: all 0.2s; /* Smooth transition for the knob */
  border-radius: 50%; /* Round shape for the knob */
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); /* Shadow for the knob */
}

/* Position the knob to the right when checked */
.toggle-container input[type="checkbox"]:checked + .toggle-slider {
  background-color: #4CAF50; /* Change to your preferred color when active */
}

.toggle-container input[type="checkbox"]:checked + .toggle-slider::after {
  transform: translateX(40px); /* Move the knob to the right */
}

/* Optional: Add hover effect for buttons */
.toggle-container:hover input[type="checkbox"] + .toggle-slider {
  box-shadow: 0 0 1px #2196F3;
}

/* Add more styles as needed for the rest of your components */

/* This CSS assumes you're using a similar HTML structure to the one provided in the JSX. Adjust as necessary for your actual markup. */
